import Icon from "components/Icon"
import { Link } from "gatsby"
import FormForgotPassword from "./components/FormForgotPassword"

import "./ForgotPassword.styl"

const ForgotPassword = () => {
  return (
    <div className="forgot-password">
      <Link
        className="forgot-password__close"
        to={ROUTES.login.path}
        data-test="link--login"
      >
        <Icon className="forgot-password__icon" name="close" />
      </Link>
      <FormForgotPassword />
    </div>
  )
}

export default ForgotPassword
