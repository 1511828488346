import { navigate } from "gatsby"
import { useState } from "react"
import services from "services"

const useFormForgotPassword = () => {
  const [sended, setSended] = useState()
  const [errors, setErrors] = useState({ message: null })

  const onSubmit = (formData) => {
    services.auth.forgotPassword(formData).then(handleForgotPassword)
  }

  const onConcluded = () => navigate(ROUTES.login.path)

  const handleForgotPassword = ({ error, message }) => {
    if (error) {
      setErrors({ message })
    } else {
      setErrors({ message: null })
      setSended(true)
    }
  }

  return {
    errors,
    sended,
    onSubmit,
    onConcluded,
  }
}

export default useFormForgotPassword
