import Form from "components/Forms/Form"
import Input from "components/Forms/Input"
import If from "components/If"
import Button from "components/Button"
import AuthFeedback from "components/Auth/Feedback"
import AuthError from "components/Auth/Error"

import useFormForgotPassword from "./hooks"

import { values, validation } from "./form"

import "./ForgotPassword.styl"

const ForgotPassword = () => {
  const { errors, sended, onConcluded, onSubmit } = useFormForgotPassword()

  return (
    <>
      <h1
        className="logged-out__title form-forgot-password__title"
        data-test="title"
      >
        Esqueci minha senha
      </h1>
      <If condition={errors.message}>
        <AuthError message={errors.message} data-test="error" />
      </If>
      <If condition={!sended}>
        <Form
          className="form-forgot-password"
          defaultValues={values}
          onSubmit={onSubmit}
          schema={validation}
        >
          <p
            className="form-forgot-password__description logged-out__description"
            data-test="description"
          >
            Digite o seu email de acesso no campo a seguir e clique no botão
            ”Enviar link” para receber um link de redefinição de senha.
          </p>
          <Input
            className="form-forgot-password__input"
            name="email"
            placeholder="Digite seu email"
            data-test="email"
          />
          {({ state: { isDisabled } }) => (
            <Button
              className="form-forgot-password__submit"
              type="submit"
              size="big"
              disabled={isDisabled}
              data-test="submit"
            >
              Enviar link
            </Button>
          )}
        </Form>
      </If>
      <If condition={sended}>
        <AuthFeedback
          icon="mail-sent"
          title="Verifique seu email"
          description="Um link de redefinição de senha foi enviado para o seu email de acesso. Caso ainda não tenha recebido, aguarde alguns minutos antes de tentar novamente e lembre-se de verificar a sua caixa de spam."
          button="Voltar para a tela de acesso"
          onClick={onConcluded}
          data-test="feedback"
        />
      </If>
    </>
  )
}

export default ForgotPassword
