import * as Yup from "yup"

export const values = {
  email: "",
}

export const validation = Yup.object().shape({
  email: Yup.string()
    .trim()
    .required("Campo obrigatório")
    .email("E-mail inválido"),
})
